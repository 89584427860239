const users = {
    title: 'User Management',
    subtitle: 'Manage user identities including creating users, editing user information, viewing user logs, password resets and deleting users',
    create: 'Add User',
    user_name: 'User',
    application_name: 'From application',
    latest_sign_in: 'Latest sign in',
    create_form_username: 'Username',
    create_form_password: 'Password',
    create_form_name: 'Full name',
    unnamed: 'Unnamed',
    search: 'Search by name, email, phone or username',
    check_user_detail: 'Check user detail',
    placeholder_title: 'User management',
    placeholder_description: 'Each user has a profile containing all user information. It consists of basic data, social identities, and custom data.',
};
export default users;
