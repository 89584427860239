const connector_details = {
    back_to_connectors: 'Connectorlara dön',
    check_readme: 'READMEye göz at',
    settings: 'General settings',
    settings_description: 'Connectors play a critical role in Logto. With their help, Logto enables end-users to use passwordless registration or sign-in and the capabilities of signing in with social accounts.',
    parameter_configuration: 'Parameter configuration',
    test_connection: 'Test connection',
    save_error_empty_config: 'Lütfen yapılandırmayı girin',
    send: 'Gönder',
    send_error_invalid_format: 'Geçersiz input',
    edit_config_label: 'json girin ',
    test_email_sender: 'eposta connectorunuzu test edin',
    test_sms_sender: 'SMS connectorunuzu test edin',
    test_email_placeholder: 'john.doe@example.com',
    test_sms_placeholder: '+90 555 123 45 67',
    test_message_sent: 'Test mesajı gönderildi',
    test_sender_description: 'Logto testler için "Generic" şablonunu kullanır. Bağlantınız doğru şekilde yapılandırılmışsa bir mesaj alacaksınız.',
    options_change_email: 'Eposta connectorunuzu değiştirin',
    options_change_sms: 'SMS connectorunuzu değiştirin',
    connector_deleted: 'Connector başarıyla silindi',
    type_email: 'Eposta connectorı',
    type_sms: 'SMS connectorı',
    type_social: 'Social connector',
    in_used_social_deletion_description: 'This connector is in-use in your sign in experience. By deleting, <name/> sign in experience will be deleted in sign in experience settings. You will need to reconfigure it if you decide to add it back.',
    in_used_passwordless_deletion_description: 'This {{name}} is in-use in your sign-in experience. By deleting, your sign-in experience will not work properly until you resolve the conflict. You will need to reconfigure it if you decide to add it back.',
    deletion_description: 'You are removing this connector. It cannot be undone, and you will need to reconfigure it if you decide to add it back.', // UNTRANSLATED
};
export default connector_details;
