const demo_app = {
    title: "You've successfully signed in the live preview!",
    subtitle: 'Voici vos informations de connexion :',
    username: "Nom d'utilisateur : ",
    user_id: "ID de l'utilisateur :",
    sign_out: 'Sign Out the Live Preview',
    continue_explore: 'Ou continuer à explorer',
    customize_sign_in_experience: "Personnaliser l'expérience de connexion",
    enable_passwordless: 'Activer la fonction sans mot de passe',
    add_social_connector: 'Ajouter un connecteur social',
};
export default demo_app;
