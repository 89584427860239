const users = {
    title: 'Gerenciamento de usuários',
    subtitle: 'Gerenciar identidades de usuários, visualização de logs de usuários, redefinições de senha e exclusão de usuários',
    create: 'Adicionar usuário',
    user_name: 'Usuário',
    application_name: 'Aplicativo',
    latest_sign_in: 'Último login',
    create_form_username: 'Nome de usuário',
    create_form_password: 'Senha',
    create_form_name: 'Nome completo',
    unnamed: 'Sem nome',
    search: 'Search by name, email, phone or username',
    check_user_detail: 'Check user detail',
    placeholder_title: 'User management',
    placeholder_description: 'Each user has a profile containing all user information. It consists of basic data, social identities, and custom data.', // UNTRANSLATED
};
export default users;
