const users = {
    title: '사용자 관리',
    subtitle: '사용자의 신원을 추가, 삭제, 수정, 조회하여 관리해보세요.',
    create: '사용자 추가',
    user_name: '사용자',
    application_name: '어플리케이션으로부터',
    latest_sign_in: '최근 로그인 시각',
    create_form_username: '사용자 이름',
    create_form_password: '비밀번호',
    create_form_name: '이름',
    unnamed: '이름없음',
    search: '이름, 이메일, 전화번호, ID로 검색',
    check_user_detail: '사용자 상세정보 확인',
    placeholder_title: 'User management',
    placeholder_description: 'Each user has a profile containing all user information. It consists of basic data, social identities, and custom data.', // UNTRANSLATED
};
export default users;
