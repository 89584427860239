const get_started = {
    progress: '시작 가이드: {{completed}}/{{total}}',
    progress_dropdown_title: '해야할 것들...',
    title: 'Something to explore to help you succeed',
    subtitle_part1: 'Logto의 가치를 얻기 위해 해야 할 것들이 있어요.',
    subtitle_part2: '설정을 마칠게요 ',
    hide_this: '가리기',
    confirm_message: '정말로 이 페이지를 가릴까요? 이 행동은 취소할 수 없어요.',
    check_preview_title: 'Check the live preview',
    check_preview_subtitle: 'Logto 로그인 경험을 체험해 보세요.',
    integration_title: 'Create and integrate your application',
    integration_subtitle: 'Set up Logto authentication for your native, single page, machine to machine, or traditional application',
    custom_sie_title: 'Customize sign-in experience',
    custom_sie_subtitle: 'Unlock a vast range of scenarios with advanced settings',
    passwordless_title: 'Scale passwordless sign in by adding your own connectors',
    passwordless_subtitle: 'Try passwordless sign in and enable a secure and frictionless experience for your customer',
    community_title: 'Join our discord community',
    community_subtitle: 'Join our public channel to chat with other developers',
    management_api_title: 'Interact with Management API',
    management_api_subtitle: 'Directly connect your authentication system to our management API',
    further_readings_title: 'Further readings',
    further_readings_subtitle: 'Check out our step-by-step, scenario-based docs without tedious concepts',
    add_rbac_title: 'Add role-based access control to protect your resources',
    add_rbac_subtitle: 'Control your resource through scalable role authorization for diverse use cases.', // UNTRANSLATED
};
export default get_started;
