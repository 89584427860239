const demo_app = {
    title: "You've successfully signed in the live preview!",
    subtitle: '여기 로그인 정보가 있어요:',
    username: '사용자 이름: ',
    user_id: '사용자 ID: ',
    sign_out: 'Sign Out the Live Preview',
    continue_explore: '또는 계속해서 살펴보기',
    customize_sign_in_experience: '로그인 경험 커스터마이징',
    enable_passwordless: 'Passwordless 활성화',
    add_social_connector: '소셜 연동 추가',
};
export default demo_app;
