const log_details = {
    back_to_logs: 'Voltar aos logs',
    back_to_user: 'Voltar para {{name}}',
    success: 'Sucesso',
    failed: 'Falhou',
    event_key: 'Event Key',
    application: 'Aplicativo',
    ip_address: 'Endereço de IP',
    user: 'Usuário',
    log_id: 'Log ID',
    time: 'Tempo',
    user_agent: 'User agent',
    tab_details: 'Detalhes',
    raw_data: 'Dados completos',
};
export default log_details;
