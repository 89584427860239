const api_resources = {
    title: 'API Ressourcen',
    subtitle: 'Lege APIs an, die du in deinen autorisierten Anwendungen verwenden kannst',
    create: 'Erstelle API Ressource',
    api_name: 'API Name',
    api_name_placeholder: 'Gib einen API Namen ein',
    api_identifier: 'API Identifikator',
    api_identifier_tip: 'Der eindeutige Identifikator der API Ressource muss eine absolute URI ohne Fragmentbezeichner (#) sein. Entspricht dem <a>Ressourcen Parameter</a> in OAuth 2.0.',
    api_resource_created: 'Die API Ressource {{name}} wurde erfolgreich angelegt',
    api_identifier_placeholder: 'https://dein-api-identifikator/',
};
export default api_resources;
