const permissions = {
    search_placeholder: 'API 또는 권한 이름으로 검색',
    search_placeholder_without_api: '권한 이름으로 검색',
    name_column: '권한',
    description_column: '설명',
    api_column: 'API',
    placeholder_title: 'Permission',
    placeholder_description: 'Permission refers to the authorization to access a resource (we call it API resource).', // UNTRANSLATED
};
export default permissions;
