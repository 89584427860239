const permissions = {
    search_placeholder: 'Search by API or permission name',
    search_placeholder_without_api: 'Search by permission name',
    name_column: 'Permission',
    description_column: 'Description',
    api_column: 'API',
    placeholder_title: 'Permission',
    placeholder_description: 'Permission refers to the authorization to access a resource (we call it API resource).', // UNTRANSLATED
};
export default permissions;
