const components = {
    uploader: {
        action_description: 'Drag and drop or browse',
        uploading: 'Uploading...',
        image_limit: 'Upload image under {{size, number}}KB, {{extensions, list(style: narrow; type: conjunction;)}} only.',
        error_upload: 'Something went wrong. File upload failed.',
        error_file_size: 'File size is too large. Please upload a file under {{size, number}}KB.',
        error_file_type: 'File type is not supported. {{extensions, list(style: narrow; type: conjunction;)}} only.', // UNTRANSLATED
    },
};
export default components;
