const general = {
    placeholder: 'Placeholder',
    skip: 'Saltar',
    next: 'Seguine',
    back: 'Back',
    retry: 'Tente novamente',
    done: 'Feito',
    search: 'Pesquisar',
    search_placeholder: 'Pesquise',
    clear_result: 'Limpar resultados',
    save: 'Guardar',
    save_changes: 'Guardar Alterações',
    saved: 'Guardado!',
    discard: 'Discard',
    loading: 'Carregando...',
    redirecting: 'Redirecionando...',
    add: 'Adicionar',
    added: 'Adicionado',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    check_out: 'Verificar',
    create: 'Criar',
    set_up: 'Configurar',
    customize: 'Customizar',
    enable: 'Ativar',
    reminder: 'Lembrete',
    delete: 'Apagar',
    more_options: 'MAIS OPÇÕES',
    close: 'Fechar',
    copy: 'Copiar',
    copying: 'Copiando',
    copied: 'Copiado',
    required: 'Necessário',
    add_another: 'Adicionar outro',
    deletion_confirmation: 'Tem a certeza que deseja eliminar isso {{title}}?',
    settings_nav: 'Definições',
    unsaved_changes_warning: 'Fez algumas alterações. Tem a certeza que deseja sair desta página?',
    leave_page: 'Sair da página',
    stay_on_page: 'Ficar na página',
    type_to_search: 'Type to search',
    got_it: 'Entendi',
    continue: 'Continue',
    page_info: '{{min, number}}-{{max, number}} of {{total, number}}',
    learn_more: 'Learn more',
    tab_errors: '{{count, number}} errors',
    skip_for_now: 'Skip for now',
    remove: 'Remove',
    visit: 'Visit',
    join: 'Join',
    try_now: 'Try Now',
    multiple_form_field: '(Multiple)',
    demo: 'Demo', // UNTRANSLATED
};
export default general;
