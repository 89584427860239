const demo_app = {
    title: "You've successfully signed in the live preview!",
    subtitle: 'Here is your log in information:',
    username: 'Benutzername: ',
    user_id: 'Benutzer ID: ',
    sign_out: 'Sign Out the Live Preview',
    continue_explore: 'Oder weiter zum Entdecken',
    customize_sign_in_experience: 'Anmeldeoberfläche anpassen',
    enable_passwordless: 'Passwordless einschalten',
    add_social_connector: 'Social Connector hinzufügen',
};
export default demo_app;
