const users = {
    title: 'Kullanıcı Yönetimi',
    subtitle: 'Kullanıcı oluşturma, kullanıcı bilgilerini düzenleme, kullanıcı kayıtlarını görüntüleme, parola sıfırlama ve kullanıcıları silme dahil olmak üzere kullanıcı kimliklerini yönetin',
    create: 'Kullanıcı ekle',
    user_name: 'Kullanıcı',
    application_name: 'Uygulamadan',
    latest_sign_in: 'En son oturum açma',
    create_form_username: 'Kullanıcı Adı',
    create_form_password: 'Şifre',
    create_form_name: 'Ad Soyad',
    unnamed: 'İsimsiz',
    search: 'Search by name, email, phone or username',
    check_user_detail: 'Check user detail',
    placeholder_title: 'User management',
    placeholder_description: 'Each user has a profile containing all user information. It consists of basic data, social identities, and custom data.', // UNTRANSLATED
};
export default users;
