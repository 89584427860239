const users = {
    title: 'Gestão de utilizadores',
    subtitle: 'Gerencie os utilizadores, incluindo a criação, edição de informações, visualização de logs, recuperações de password e exclusões',
    create: 'Adicionar usuário',
    user_name: 'Utilizador',
    application_name: 'Do app',
    latest_sign_in: 'Último login',
    create_form_username: 'Utilizador',
    create_form_password: 'Password',
    create_form_name: 'Nome completo',
    unnamed: 'Sem nome',
    search: 'Search by name, email, phone or username',
    check_user_detail: 'Check user detail',
    placeholder_title: 'User management',
    placeholder_description: 'Each user has a profile containing all user information. It consists of basic data, social identities, and custom data.', // UNTRANSLATED
};
export default users;
