const get_started = {
    progress: 'Get started guide: {{completed}}/{{total}}',
    progress_dropdown_title: 'A few things you can do...',
    title: 'Something to explore to help you succeed',
    subtitle_part1: 'A few things you can do to quickly get value of Logto',
    subtitle_part2: 'I’m a pro and have completed all steps. ',
    hide_this: 'Hide this',
    confirm_message: 'Are you sure you want to hide this page? This action cannot be undone.',
    check_preview_title: 'Check the live preview',
    check_preview_subtitle: 'Try Logto sign-in experience now to see how it works',
    integration_title: 'Create and integrate your application',
    integration_subtitle: 'Set up Logto authentication for your native, single page, machine to machine, or traditional application',
    custom_sie_title: 'Customize sign-in experience',
    custom_sie_subtitle: 'Unlock a vast range of scenarios with advanced settings',
    passwordless_title: 'Scale passwordless sign in by adding your own connectors',
    passwordless_subtitle: 'Try passwordless sign in and enable a secure and frictionless experience for your customer',
    community_title: 'Join our discord community',
    community_subtitle: 'Join our public channel to chat with other developers',
    management_api_title: 'Interact with Management API',
    management_api_subtitle: 'Directly connect your authentication system to our management API',
    further_readings_title: 'Further readings',
    further_readings_subtitle: 'Check out our step-by-step, scenario-based docs without tedious concepts',
    add_rbac_title: 'Add role-based access control to protect your resources',
    add_rbac_subtitle: 'Control your resource through scalable role authorization for diverse use cases.',
};
export default get_started;
