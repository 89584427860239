const general = {
    placeholder: 'Placeholder',
    skip: 'Passer',
    next: 'Suivant',
    back: 'Back',
    retry: 'Essayez à nouveau',
    done: 'Terminé',
    search: 'Rechercher',
    search_placeholder: 'Rechercher...',
    clear_result: 'Effacer les résultats',
    save: 'Sauvegarder',
    save_changes: 'Sauvegarder les modifications',
    saved: 'Sauvegardé !',
    discard: 'Discard',
    loading: 'Chargement...',
    redirecting: 'Redirection...',
    add: 'Ajouter',
    added: 'Ajouté',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    check_out: 'Vérifier',
    create: 'Créer',
    set_up: 'Configurer',
    customize: 'Customiser',
    enable: 'Activer',
    reminder: 'Rappeler',
    delete: 'Supprimer',
    more_options: "PLUS D'OPTIONS",
    close: 'Fermer',
    copy: 'Copier',
    copying: 'Copie',
    copied: 'Copié',
    required: 'Requis',
    add_another: 'Ajouter un autre',
    deletion_confirmation: 'Êtes-vous sûr de vouloir supprimer ce {{title}} ?',
    settings_nav: 'Paramètres',
    unsaved_changes_warning: 'Vous avez effectué des changements. Êtes-vous sûr de vouloir quitter cette page ?',
    leave_page: 'Quittez la page',
    stay_on_page: 'Rester sur la page',
    type_to_search: 'Type to search',
    got_it: 'Compris.',
    continue: 'Continue',
    page_info: '{{min, number}}-{{max, number}} of {{total, number}}',
    learn_more: 'Learn more',
    tab_errors: '{{count, number}} errors',
    skip_for_now: 'Skip for now',
    remove: 'Remove',
    visit: 'Visit',
    join: 'Join',
    try_now: 'Try Now',
    multiple_form_field: '(Multiple)',
    demo: 'Demo', // UNTRANSLATED
};
export default general;
