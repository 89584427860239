const users = {
    title: 'Benutzerverwaltung',
    subtitle: 'Verwalten von Benutzeridentitäten, einschließlich des Anlegens von Benutzern, Bearbeiten von Benutzerinformationen, Anzeigen von Benutzer-Logs, Zurücksetzen von Passwörtern und Löschen von Benutzern',
    create: 'Benutzer hinzufügen',
    user_name: 'Benutzer',
    application_name: 'Anwendungsname',
    latest_sign_in: 'Letzte Anmeldung',
    create_form_username: 'Benutzername',
    create_form_password: 'Passwort',
    create_form_name: 'Name',
    unnamed: 'Unbenannt',
    search: 'Search by name, email, phone or username',
    check_user_detail: 'Check user detail',
    placeholder_title: 'User management',
    placeholder_description: 'Each user has a profile containing all user information. It consists of basic data, social identities, and custom data.', // UNTRANSLATED
};
export default users;
