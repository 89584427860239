const roles = {
    title: '역할',
    subtitle: '역할은 사용자가 무엇을 할 수 있는지를 결정하는 권한을 포함해요. RBAC는 사용자에게 특정 행동에 대한 접근 권한을 부여하기 위해 역할을 사용해요.',
    create: '역할 생성',
    role_name: '역할 이름',
    role_description: '설명',
    role_name_placeholder: '역할 이름을 입력하세요',
    role_description_placeholder: '역할 설명을 입력하세요',
    assigned_users: '할당된 사용자',
    assign_permissions: '권한 할당',
    create_role_title: '역할 생성',
    create_role_description: '당신의 애플리케이션을 위한 역할을 생성하고 관리해요. 역할은 권한들의 모음을 포함하며 사용자에게 할당될 수 있어요.',
    create_role_button: '역할 생성',
    role_created: '역할 {{name}}이 성공적으로 생성되었어요!',
    search: '역할 이름, 설명, ID로 검색',
    placeholder_title: 'Roles',
    placeholder_description: 'Roles are a grouping of permissions that can be assigned to users. Be sure to add permission first before create roles.', // UNTRANSLATED
};
export default roles;
