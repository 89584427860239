const demo_app = {
    title: "You've successfully signed in the live preview!",
    subtitle: 'Sisteme giriş bilgileriniz:',
    username: 'Kullanıcı Adı: ',
    user_id: 'Kullanıcı Kimliği: ',
    sign_out: 'Sign Out the Live Preview',
    continue_explore: 'Veya keşfetmeye devam et',
    customize_sign_in_experience: 'Oturum Açma Deneyimini Özelleştir',
    enable_passwordless: 'Şifresiz Etkinleştir',
    add_social_connector: 'Social Connector Ekle',
};
export default demo_app;
