const log_details = {
    back_to_logs: 'Zurück zu Audit Logs',
    back_to_user: 'Zurück zu {{name}}',
    success: 'Erfolgreich',
    failed: 'Fehlgeschlagen',
    event_key: 'Event Key',
    application: 'Anwendung',
    ip_address: 'IP Adresse',
    user: 'Benutzer',
    log_id: 'Log ID',
    time: 'Zeit',
    user_agent: 'User agent',
    tab_details: 'Details',
    raw_data: 'Rohe Daten',
};
export default log_details;
