@use '@/scss/underscore' as _;

.content {
  align-items: center;
  max-width: 858px;
  padding: _.unit(12) _.unit(32);
}

.congratsImage {
  width: 160px;
  height: 160px;
}

.title {
  margin-top: _.unit(6);
  font: var(--font-title-1);
  text-align: center;
}

.description {
  margin: _.unit(1) 0 _.unit(6) 0;
  font: var(--font-body-2);
  text-align: center;
}

.strong {
  font-weight: 500;
}

.buttonIcon {
  opacity: 70%;
}

.divider {
  width: 100%;
  margin: _.unit(8) 0;
}

.reservation {
  background-color: var(--color-layer-light);
  border: unset;
}
